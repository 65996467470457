import React from "react"
import Layout from "../Components/Layout"
import Services from "../Components/Services"

const tjanster = () => {
  return (
    <Layout>
      <Services />
    </Layout>
  )
}

export default tjanster
