import React from "react"

const ServiceItem = ({ image, title, desc }) => {
  return (
    <div
      style={{
        display: "flex",
        padding: 20,
        alignItems: "center",
      }}
    >
      <img
        width={50}
        height={50}
        src={image ?? "/assets/logo.png"}
        alt={title}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "600px",
          marginLeft: "10px",
        }}
      >
        <h2>{title}</h2>
        <p>{desc}</p>
      </div>
    </div>
  )
}

export default ServiceItem
