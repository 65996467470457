import React from "react"
import ServiceItem from "./ServiceItem"
import charging from "../assets/charging.png"
import tools from "../assets/tools.png"
import lightbulb from "../assets/lightbulb.png"
import constructionWorker from "../assets/construction-worker.png"
import lan from "../assets/lan.png"
import fridge from "../assets/fridge.png"
import plug from "../assets/plug.png"

const Services = () => {
  const serviceItems = [
    {
      image: charging,
      title: "Laddstationer",
      desc:
        "Vi installerar laddstationer både till privatpersoner och större anlägggningar såsom bostadsrättsföreningar.",
    },
    {
      image: tools,
      title: "Service och underhåll",
      desc: "Vi utför sevice och underhåll på befintliga elanläggningar.",
    },
    {
      image: lightbulb,
      title: "Belysning",
      desc:
        "Vi utför installation av belysning i privata och publika miljöer samt energioptimering av trapphusbelysning till bostadsrättsförening etc.",
    },
    {
      image: constructionWorker,
      title: "Elinstallation",
      desc: "Vi erbjuder elinstallation i nyproduktion och renovering.",
    },
    {
      image: lan,
      title: "Data nätverk / fiber",
      desc: "Vi utför installation av datanätverk etc.",
    },
    {
      image: plug,
      title: "Effektutredning",
      desc: "Vi utför mätning av er elanläggning och förbrukning.",
    },
    {
      image: fridge,
      title: "Smarta hem",
      desc: "Vi installerar smarta hem lösningar som styrning av belysning.",
    },
  ]
  return (
    <div style={{ margin: "2rem 0" }}>
      {serviceItems.map(i => (
        <ServiceItem image={i.image} title={i.title} desc={i.desc} />
      ))}
    </div>
  )
}

export default Services
